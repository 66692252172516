import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  fulfilments: [],
  totalPages: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

export const getFulfilments = createAsyncThunk(
  'fulfilments/getFulfilments',
  async (params) => {
    const response = await handleAPI('platform/fulfillments', 'get', params);
    return response;
  }
);

const fulfilmentSlice = createSlice({
  name: 'fulfilment',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFulfilments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFulfilments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fulfilments = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getFulfilments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default fulfilmentSlice.reducer;
