import React from 'react';
import Button from '../../atoms/button/Button';
import { openOdinForgeReports } from '../../../utils/api/odinForge';

const ReportsError = () => {
  const handleReports = () => {
    openOdinForgeReports(false);
  };

  return (
    <div className='flex flex-col items-center justify-center gap-5 p-3 text-center'>
      <p className='text-4xl font-bold'>{'Error logging into reports'}</p>

      <p className='text-gray-1'>
        {'Please try again or contact the administrator'}
      </p>

      <div className='mt-6 flex flex-col items-center gap-5 md:flex-row'>
        <Button
          title='Try again'
          onClickHandler={() => handleReports()}
        />
      </div>
    </div>
  );
};

export default ReportsError;
