import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const splitFilledAndEmptyParams = (params) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => {
      (!value ? acc.empty : acc.filled).push([key, value]);
      return acc;
    },
    { filled: [], empty: [] }
  );

const useCustomSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);

  const deleteSearchParams = useCallback(
    (keys = [], opts = { replace: true }) => {
      setSearchParams((params) => {
        keys.forEach((key) => params.delete(key));
        return params;
      }, opts);
    },
    [setSearchParams]
  );

  const updateSearchParams = useCallback(
    (params, opts = { replace: true, updateEmpty: false }) => {
      if (Object.keys(params).length || opts.updateEmpty) {
        setSearchParams((current) => {
          const splitParams = splitFilledAndEmptyParams(params);

          splitParams.empty.forEach(([key]) => current.delete(key));

          return {
            ...Object.fromEntries(current),
            ...Object.fromEntries(splitParams.filled),
          };
        }, opts);
      }
    },
    [setSearchParams]
  );

  return {
    searchParams,
    paramsObject,
    setSearchParams,
    updateSearchParams,
    deleteSearchParams,
  };
};

export default useCustomSearchParams;
