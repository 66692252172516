import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  clicks: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getClicks = createAsyncThunk(
  'clicks/getClicks',
  async (params) => {
    const response = await handleAPI('platform/clicks', 'get', params);
    return response;
  }
);

const clickSlice = createSlice({
  name: 'clicks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getClicks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClicks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clicks = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getClicks.rejected, (state, action) => {
      state.staisLoadingtus = false;
      state.error = action.payload;
    });
  },
});

export default clickSlice.reducer;
