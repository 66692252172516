import { FilterFieldType } from './filterFieldType';

export const CommonFilterFields = {
  START_DATE: {
    key: 'startDate',
    type: FilterFieldType.DATE,
  },

  END_DATE: {
    key: 'endDate',
    type: FilterFieldType.DATE,
  },
};
