import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import useCommanForm from '../../../hooks/form/useCommanForm';
import Button from '../../atoms/button/Button';
import DatePicker from '../DateRangePicker/DatePicker';
import FilterBadgeList from '../../organism/FilterBadgeList/FilterBadgeList';
import Filterbar from '../Filterbar/Filterbar';
import { Controller } from 'react-hook-form';
import { DateRangeOptions } from 'constants/options/dateRangeOptions';
import { useMediaQuery } from '@mui/material';
import MultiSelect from 'components/atoms/multiSelect/MultiSelect';
import * as yup from 'yup';
import DateTimeRangePicker from '../DateTimeRangePicker/DateTimeRangePicker';
import { TimeColumnOptions } from '../../../constants/options/timeColumnOptions';
import moment from 'moment';
import { handleAPI } from 'utils/api/api';
import { deserialize } from 'deserialize-json-api';
import { FilterFieldType } from 'constants/filters/filterFieldType';
import { CommonFilterFields } from 'constants/filters/commonFilterFields';
import useCustomSearchParams from 'hooks/searchParams/useCustomSearchParams';
import { TimeColumnParam } from 'constants/params/paramKeys';
import { parseBool } from 'utils/helper/helper';

const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const fieldToParam = (key, value, fields) => {
  const field = Object.values(fields).find((f) => f.key === key);

  if (!key || !value || !field) return null;

  switch (field.type) {
    case FilterFieldType.TEXT:
    case FilterFieldType.TOGGLE:
      return value;

    case FilterFieldType.DROPDOWN:
      return value.map((v) => v.id).join(',');

    case FilterFieldType.DATE:
      return value.format(ISO_DATE_FORMAT);

    default:
      return null;
  }
};

const formToParams = (form, fields) => {
  return Object.fromEntries(
    Object.entries(form).map(([key, value]) => [
      key,
      fieldToParam(key, value, fields),
    ])
  );
};

const fetchSelectedValue = async (endpoint, id) => {
  const response = await handleAPI(endpoint, 'GET', { 'filter[id_eq]': id });
  return deserialize(response.data).data[0];
};

const findSelectedOption = async (key, value, fields) => {
  const array = value.split(',');
  const field = Object.values(fields).find((f) => f.key === key);

  if (!field) return [];

  const selectedOptions = await Promise.all(
    array.map(async (id) =>
      field.endpoint
        ? field.fetchSelectedValue
          ? await field.fetchSelectedValue(id)
          : await fetchSelectedValue(field.endpoint, id)
        : Object.values(field.options).find((o) => String(o.id) === id)
    )
  );

  return selectedOptions.filter((o) => !!o);
};

const paramToField = async (key, value, fields) => {
  const field = Object.values(fields).find((f) => f.key === key);

  if (!key || !value || !field) return null;

  switch (field.type) {
    case FilterFieldType.TEXT:
      return value;

    case FilterFieldType.TOGGLE:
      return parseBool(value);

    case FilterFieldType.DROPDOWN:
      return await findSelectedOption(key, value, fields);

    case FilterFieldType.DATE:
      return moment(value);

    default:
      return null;
  }
};

const paramsToForm = async (params, fields) => {
  const entries = await Promise.all(
    Array.from(params).map(async ([key, value]) => [
      key,
      await paramToField(key, value, fields),
    ])
  );

  return entries.filter(([, v]) => !!v);
};

const schema = yup.object();

const FilterBarWrapper = ({
  FilterForm,
  fields,
  dateRange,
  setDateRange,
  showDateInputs = true,
  timeColumn,
  setTimeColumn,
  showTimeColumn = false,
  urlFilters = false,
  resetParams = {},
  onSubmit = () => {},
}) => {
  const defaultValues = {
    startDate: dateRange?.start ?? null,
    endDate: dateRange?.end ?? null,
  };

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    resetField,
    setValue,
    watch,
  } = useCommanForm(schema, defaultValues);

  const { searchParams, updateSearchParams, deleteSearchParams } =
    useCustomSearchParams();

  const isTablet = useMediaQuery('(max-width: 1280px)');
  const showDateRange = dateRange && setDateRange;

  const startDateWatch = watch(CommonFilterFields.START_DATE.key);
  const endDateWatch = watch(CommonFilterFields.END_DATE.key);

  const formFields = useMemo(
    () => Object.assign({}, fields, CommonFilterFields),
    [fields]
  );

  useEffect(() => {
    if (showDateRange && (!startDateWatch || !endDateWatch)) {
      setDateRange(DateRangeOptions.CUSTOM);
    }
  }, [endDateWatch, setDateRange, showDateRange, startDateWatch]);

  const [appliedFilters, setAppliedFilters] = useState({
    startDate: defaultValues.startDate,
    endDate: defaultValues.endDate,
  });

  useEffect(() => {
    const setFiltersFromParams = async () => {
      const formEntries = await paramsToForm(searchParams, formFields);
      formEntries.forEach(([k, v]) => setValue(k, v));
      setAppliedFilters(Object.fromEntries(formEntries));
    };

    if (urlFilters) setFiltersFromParams();
  }, [formFields, searchParams, setValue, urlFilters]);

  const handleFormSubmit = (data) => {
    onSubmit?.(data);
    setAppliedFilters(getValues());

    if (urlFilters) {
      updateSearchParams({ ...formToParams(data, formFields), ...resetParams });
    }
  };

  const handleResetIndividualField = (key) => {
    resetField(key);
    setValue(key, null);

    if (urlFilters) {
      deleteSearchParams([key]);
      updateSearchParams(resetParams);
    } else {
      handleSubmit(handleFormSubmit)();
    }
  };

  const handleCancel = () => {
    reset({ startDate: null, endDate: null });

    if (urlFilters) {
      deleteSearchParams(Object.values(formFields).map((f) => f.key));
      updateSearchParams(resetParams);
    } else {
      handleSubmit(handleFormSubmit)();
    }
  };

  const setToCustomRange = () => {
    showDateRange && setDateRange(DateRangeOptions.CUSTOM);
  };

  const setSelectedTimeColumn = (option) => {
    if (urlFilters) {
      updateSearchParams({ timeColumn: option.id });
    }

    setTimeColumn?.(option);
  };

  const selectedTimeColumn = useMemo(() => {
    const timeColumnParam = searchParams.get(TimeColumnParam);

    return timeColumnParam
      ? Object.values(TimeColumnOptions).find((o) => o.id === timeColumnParam)
      : timeColumn;
  }, [searchParams, timeColumn]);

  return (
    <>
      <Filterbar
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleCancel}
        onlyModal
      >
        <FilterForm
          register={register}
          control={control}
        />
      </Filterbar>

      {showDateInputs && (
        <>
          <div className='flex w-full flex-col gap-3 lg:w-max lg:flex-row [&>*]:w-full lg:[&>*]:w-auto [&>div>div>button]:w-full'>
            {showDateRange && (
              <MultiSelect
                className='lg:w-52'
                options={DateRangeOptions}
                sortOptions={false}
                placeholder='Date Range'
                selectedOptions={dateRange}
                setSelectedOptions={setDateRange}
                isClearable={false}
                onChange={(v) => {
                  setValue(CommonFilterFields.START_DATE.key, v.start);
                  setValue(CommonFilterFields.END_DATE.key, v.end);

                  if (isTablet) {
                    handleSubmit(handleFormSubmit)();
                  }
                }}
              />
            )}

            {isTablet ? (
              <>
                <Controller
                  name={CommonFilterFields.START_DATE.key}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      startEndOfDay={'start'}
                      startIcon
                      onChange={setToCustomRange}
                      onSubmit={handleSubmit(handleFormSubmit)}
                      onCancel={() =>
                        handleResetIndividualField(
                          CommonFilterFields.START_DATE.key
                        )
                      }
                      showTime
                    />
                  )}
                />

                <Controller
                  name={CommonFilterFields.END_DATE.key}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      startEndOfDay={'end'}
                      endIcon
                      onChange={setToCustomRange}
                      onSubmit={handleSubmit(handleFormSubmit)}
                      onCancel={() =>
                        handleResetIndividualField(
                          CommonFilterFields.END_DATE.key
                        )
                      }
                      showTime
                    />
                  )}
                />
              </>
            ) : (
              <DateTimeRangePicker
                control={control}
                watch={watch}
                setToCustomRange={setToCustomRange}
              />
            )}
            {showTimeColumn && (
              <MultiSelect
                isMulti={false}
                labelClassName='font-medium'
                isClearable={false}
                isSearchable={false}
                selectedOptions={selectedTimeColumn}
                setSelectedOptions={setSelectedTimeColumn}
                options={TimeColumnOptions}
                sortOptions={false}
                className={'lg:w-[180px]'}
              />
            )}
          </div>

          <Button
            title='Apply'
            type='submit'
            className='hidden lg:flex'
            onClickHandler={handleSubmit(handleFormSubmit)}
          />
        </>
      )}

      <FilterBadgeList
        filters={appliedFilters}
        onClear={handleResetIndividualField}
        onClearAll={handleCancel}
        fields={formFields}
      />
    </>
  );
};

export default FilterBarWrapper;
