export const PaginationParam = {
  CURRENT_PAGE: 'currentPage',
  PER_PAGE: 'perPage',
};

export const SortParam = {
  COLUMN: 'sortColumn',
  ORDER: 'sortOrder',
};

export const TimeColumnParam = 'timeColumn';
