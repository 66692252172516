import React from 'react';
import XIcon from '../../icons/XIcon';

const FilterBadge = ({ label, value, onClose, color = 'blue' }) => {
  const colors = {
    green:
      'text-state-green bg-state-green-background [&_path]:stroke-state-green',
    blue: 'text-primary bg-background-header [&_path]:stroke-primary',
  };

  return (
    <div
      className={`flex items-center gap-[6px] rounded-full px-3 py-[2px] text-sm ${colors[color]}`}
    >
      <p>
        <b className='capitalize'>
          {label}
          {value && ':'}
        </b>
        &nbsp;{value}
      </p>

      <button
        onClick={() => onClose()}
        className='mt-[1px] pl-1'
      >
        <XIcon
          size='xs'
          className='stroke-2'
        />
      </button>
    </div>
  );
};

export default FilterBadge;
