import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import qs from 'qs';
import { getCookie, getUrlInfo } from 'utils/helper/helper';
import { handleAPI } from './api';

export const ODINFORGE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api-bi.swisscrm.com/api/'
    : 'https://reports-api.swisspriv.com/api/';

const handleOdinForgeAPI = (
  path,
  method,
  params,
  data = null,
  responseType = 'json'
) => {
  const headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
  };

  let url = ODINFORGE_URL + path;

  if (params) {
    url = url + '?' + qs.stringify(params, { arrayFormat: 'brackets' });
  }

  return axios({
    method,
    url,
    ...(data && { data }),
    responseType,
    headers,
  });
};

/**
 * Opens the Odin Forge Reports page.
 *
 * @param {boolean} [newWindow=true] - Determines whether to open in a new tab or the current tab.
 *                                      If true, the reports open in a new tab. If false, it opens in the same tab.
 * @returns {Promise<boolean>} - Returns false if there's an error from the reports API.
 */
export const openOdinForgeReports = async (newWindow = true) => {
  const token = getCookie('token');
  const role = localStorage.getItem('role');
  const isAffiliate = role === 'affiliate';

  if (!token) return;

  const userId = jwtDecode(token).user_id;
  const crmData = { user: { set_auto_login_token_expiry: true } };

  const apiResponse = await handleAPI(
    `platform/users/${userId}`,
    'PUT',
    '',
    crmData
  );

  const odinForgeData = {
    token: apiResponse.data.data.attributes.auto_login_token,
    tenant: getUrlInfo().subdomain,
  };

  let odinForgeResponse;

  try {
    odinForgeResponse = await handleOdinForgeAPI(
      'user/autoLogin',
      'POST',
      '',
      odinForgeData
    );
  } catch (e) {
    return false;
  }

  if (odinForgeResponse?.data?.status) {
    if (newWindow && !isAffiliate) {
      window.open(odinForgeResponse.data.redirectUrl, '_blank');
    } else {
      window.location.href = odinForgeResponse.data.redirectUrl;
    }
    return true;
  }
  return false;
};
