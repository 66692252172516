import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const AccountLayout = lazy(
  () => import('../../components/layout/user/AccountLayout')
);
const RecentActivity = lazy(
  () => import('../../components/pages/account/RecentActivity')
);
const Account = lazy(() => import('../../components/pages/account/Account'));
const Notifications = lazy(
  () => import('../../components/pages/account/Notifications')
);
const TestOrders = lazy(
  () => import('../../components/pages/order/testOrders/TestOrders')
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const accountRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });

  return {
    path: paths.getAccountLayout,
    element: createLazyComponent(AccountLayout),
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace={true}
            to={paths.recentActivities}
          />
        ),
      },
      createRoute(paths.recentActivities, RecentActivity),
      (abilities?.settings?.account?.read ?? false) &&
        createRoute(paths.account, Account),
      (abilities?.settings?.notification?.read ?? false) &&
        createRoute(paths.notification, Notifications),
      (abilities?.orders?.order?.read ?? false) &&
        createRoute(paths.accountTestOrders, TestOrders),
    ],
  };
};
