import { getBaseDomainFromUrl, getUrlInfo } from 'utils/helper/helper';

const { protocol, subdomain } = getUrlInfo();

export const BACKEND_URL =
  process.env.REACT_APP_ENV === 'production'
    ? `${protocol}//${subdomain}${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_BASE_PATH}`
    : `${protocol}//${subdomain}.${process.env.REACT_APP_BACKEND_DOMAIN}${process.env.REACT_APP_API_BASE_PATH}`;

export const TENANT = subdomain;

// the way to reliably get the domain, since it can have the port on local dev and staging prepended on staging
export const BASE_DOMAIN = getBaseDomainFromUrl(window.location.hostname);
