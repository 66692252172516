import React from 'react';

const ArrowDropUpDown = ({ className, direction = 'up' }) => {
  const isUp = direction === 'up';
  return (
    <svg
      className={className}
      fill={'currentColor'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 352.3 176.77'
      style={{ transform: isUp ? 'none' : 'rotate(180deg)' }}
    >
      <path d='M0 176.77 L176.15 0 L352.3 176.77 L0 176.77 Z' />
    </svg>
  );
};

export default ArrowDropUpDown;
