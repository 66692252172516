import React, { useMemo } from 'react';
import FilterBadge from '../../molecule/FilterBadge/FilterBadge';
import {
  camelToTitleCase,
  formatStatus,
  isArray,
  isDate,
  isMoment,
  isNumber,
  isObject,
  isString,
  timeFormatter,
} from 'utils/helper/helper';
import { FilterFieldType } from 'constants/filters/filterFieldType';

const getValueByType = (field, value) => {
  switch (field?.type) {
    case FilterFieldType.TEXT:
      return value;

    case FilterFieldType.TOGGLE:
      return undefined;

    case FilterFieldType.DROPDOWN:
      return value.map((v) => v[field.displayValue] ?? v.name).join(', ');

    case FilterFieldType.DATE:
      return timeFormatter(value.toDate());

    default:
      return undefined;
  }
};

const getValueInferType = (value) => {
  switch (true) {
    case isString(value) || isNumber(value):
      return value;

    case isArray(value):
      return value.map((v) => v.name ?? v.type).join(', ');

    case isMoment(value):
      return timeFormatter(value.toDate());

    case isDate(value):
      return timeFormatter(value);

    case isObject(value):
      return value.name;

    default:
      return undefined;
  }
};

const filterLabelMapping = {
  ipAddress: 'IP Address',
};

/* Handling edge-cases of capitalization */
const getFilterLabel = (key) => {
  return filterLabelMapping[key] ?? formatStatus(camelToTitleCase(key));
};

const FilterBadgeList = ({ filters, onClear, onClearAll, fields }) => {
  const entries = useMemo(
    () =>
      Object.entries(filters)
        ?.filter(([, value]) =>
          Array.isArray(value) ? !!value.length : !!value
        )
        .map(([key, value]) => {
          const field = Object.values(fields).find((f) => f.key === key);

          return {
            key,
            label: field?.label,
            value: field
              ? getValueByType(field, value)
              : getValueInferType(value),
          };
        }),
    [fields, filters]
  );

  const shouldShow = useMemo(() => !!entries.length, [entries.length]);

  return (
    <>
      {shouldShow && (
        <div className='flex w-full flex-wrap gap-2'>
          {entries.map((entry, index) => (
            <FilterBadge
              key={entry.key}
              label={entry.label ?? getFilterLabel(entry.key)}
              value={entry.value}
              onClose={() => onClear(entry.key)}
              color={index % 2 === 0 ? 'blue' : 'green'}
            />
          ))}

          <button
            onClick={onClearAll}
            className='rounded-full border border-background-header px-3 py-[2px] text-sm text-primary'
          >
            Clear all
          </button>
        </div>
      )}
    </>
  );
};

export default FilterBadgeList;
